import React from 'react'
import './ErrorAlert.css'
const ErrorAlert = ({message, setMessage}) => {
  return (
    <div className="pop-up-overlay">
      <div className='error__alert pop-up gradient__bg'>
        <h3>Error</h3>
        <p>{message}</p>
        <div className="error__alert--button">
          <button onClick={()=>{setMessage('')}} className='gradient__bg--blue'>Ok</button>
        </div>
      </div>
    </div>
  )
}

export default ErrorAlert