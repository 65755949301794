import { createUserWithEmailAndPassword, GoogleAuthProvider, sendEmailVerification, signInWithPopup } from 'firebase/auth'
import { useEffect } from 'react'
import { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { auth } from '../../App/Functions/firebase-config'
import google from './google.png'
const SignUp = ({ foundEmail, setLoading, userFound }) => {

  const [signInPassword, setSignInPassword]=useState('')
  const [confirmSignInPassword, setConfirmSignInPassword]=useState('')
  const [passwordVisible,setPasswordVisible] = useState(false)
  const [userDetails,setUserDetails] = useState(userFound ? userFound :{
    fullName: '',
    email: foundEmail ? foundEmail : '',
  })

  useEffect(()=>{
    setUserDetails({...userDetails, email: foundEmail})
  },[ foundEmail ])

  const isFilled = confirmSignInPassword.length > 7 && confirmSignInPassword === signInPassword

  const signInGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("Signed in with Google:", user.displayName);
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
    }
  }

  const createUserAccount = async () => {
    setLoading('Creating your account');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, userDetails.email, confirmSignInPassword);
      await sendEmailVerification(userCredential.user);
    } catch (err) {
      if (err.message.includes('auth/email-already-in-use')) {
        const err =('email already in use');
        alert(err)
      }
    }
  }


  return (
    <>
      <div className="sign__up__input">
        <input
          type="email"
          placeholder='Enter your Email'
          value={userDetails.email}
          onChange={(e)=>{setUserDetails({...userDetails, email: e.target.value})}}
        />
      </div>

      <div className="sign__up__input password">
        <input 
          type={passwordVisible ? 'text' : 'password'}
          placeholder='Enter your password' 
          value={signInPassword}
          onChange={(e)=>{setSignInPassword(e.target.value)}} 
        />
        <span className='center__flex span__hover' onClick={()=>{setPasswordVisible(!passwordVisible)}}>
          {passwordVisible ? <FaEyeSlash /> : <FaEye/>}
        </span>
      </div>

      <div className="sign__up__input enter">
        <input 
          type={passwordVisible ? 'text' : 'password'}
          placeholder='Re Enter your password' 
          value={confirmSignInPassword}
          onChange={(e)=>{setConfirmSignInPassword(e.target.value)}} 
        />
        <button disabled={!isFilled} className={`${!isFilled ? 'disabled' : 'span__hover'} center__flex `} onClick={createUserAccount}>SIGN UP</button>
      </div>

      <div className='flex__end'>
        <div className='google__sign__in  span__hover'  onClick={signInGoogle} >
          <p>Sign up with</p>
          <img src={google} alt="sign in google" />
        </div>
      </div>
    </>
  )
}

export default SignUp