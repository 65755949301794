import React from 'react';

const LoadingSpinner = () => {
    return (
        <svg style={{zIndex: 1000}} width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
          <style>
            {`.spinner_9Mto {
              animation: spinner_5GqJ 1.6s linear infinite;
              animation-delay: -1.6s;
              fill: #F1C232;
            }
            .spinner_bb12 {
              animation-delay: -1s;
              fill: #F1C232;
            }
            @keyframes spinner_5GqJ {
              12.5% {
                x: 26px;
                y: 2px;
              }
              25% {
                x: 26px;
                y: 2px;
              }
              37.5% {
                x: 26px;
                y: 26px;
              }
              50% {
                x: 26px;
                y: 26px;
              }
              62.5% {
                x: 2px;
                y: 26px;
              }
              75% {
                x: 2px;
                y: 26px;
              }
              87.5% {
                x: 2px;
                y: 2px;
              }
            }`}
          </style>
          <rect className="spinner_9Mto" x="2" y="2" rx="2" width="20" height="20" />
          <rect className="spinner_9Mto spinner_bb12" x="2" y="2" rx="2" width="20" height="20" />
        </svg>
      );
    }
export default LoadingSpinner;

