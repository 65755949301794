import React from 'react'
import LoadingSpinner from './LoadingSpinner'
import './Loading.css'
const Loading = ({message}) => {
  return (
    <div className='loading-page'>
      <LoadingSpinner />
      <h4>
        {message && message}

      </h4>
    </div>
  )
}

export default Loading