import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { auth } from '../../../App/Functions/firebase-config';
import './ResetPassword.css'

const ResetPassword = ({setResetPassword, setSuccess}) => {

  const [emailAddress, setEmailAddress] = useState('');
  
  const resetSend = () => {
    sendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        setResetPassword(false)
        setSuccess('Email sent')
      })
      .catch((error) => {
        setResetPassword(false)
        setSuccess('Please retry')
      });    
  }

  return (
    <div className='pop-up reset__password'>
      <h2>We'll send a link to your email</h2>
      <div className="reset__input">

      <input 
        type="email"
        value={emailAddress}
        onChange={(e)=>{setEmailAddress(e.target.value)}}
        placeholder='Enter your email'
      />
      <button onClick={()=>{resetSend()}} className={emailAddress ? 'span__hover' : 'disabled'}>Reset</button>
      </div>
    </div>
  )
}

export default ResetPassword