import { useState } from 'react'
import { FaCheckSquare, FaEye, FaEyeSlash, FaRegSquare } from 'react-icons/fa';
import { setPersistence, browserLocalPersistence, browserSessionPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../App/Functions/firebase-config';
import { useEffect } from 'react';
const SignIn = ({setMessage, setLoading,userFound,isFromDash,signInFromDash}) => {
  
  const [passwordVisible,setPasswordVisible] = useState(false)
  const [signInEmail,setSignInEmail]=useState('')
  const [signInPassword,setSignInPassword]=useState('')
  const [rememberMe, setRememberMe] = useState(false);
  const isFilled = signInEmail.length > 0 && signInPassword.length > 7

  useEffect(()=>{
    if(userFound){
      setLoading(true)
      setSignInEmail(userFound.email)
      setSignInPassword('abscdefghuij')
    }
  },[])

  const signIn = async () => {
    if(signInEmail === '' || signInPassword.length < 8){
      const err = 'Please fill out email and password'
      setMessage(err)
      return;
    }
    try {
      setLoading('Locating your account')
      const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;
      await setPersistence(auth, persistence);
      await signInWithEmailAndPassword(auth, signInEmail,  signInPassword);
      if(isFromDash){
        await signInFromDash()
      }
    } catch (error) {
      if(error.code.includes('auth/user-not-found')) {
        const err = 'Invalid email or password'
        setMessage(err)
        setLoading(false)
      }
      if (error.code.includes('auth/invalid-email')) {
        const err = 'Invalid email or password'
        setMessage(err)
        setLoading(false)
      }
      if(error.code.includes('auth/wrong-password')) {
        setMessage('Incorrect password')
        setLoading(false)
      }
    }
  };

  return (
      <>
        <div className="sign__up__input">
          <input 
            type="email"
            placeholder='Enter your email address'
            value={signInEmail}
            onChange={(e)=>{setSignInEmail(e.target.value)}}
          />
        </div>
        <div className="sign__up__input password">
          <input 
            type={passwordVisible ? 'text' : 'password'}
            placeholder='Enter your password' 
            value={signInPassword}
            onChange={(e)=>{setSignInPassword(e.target.value)}} 
          />
          <span className='center__flex span__hover' onClick={()=>{setPasswordVisible(!passwordVisible)}}>
            {passwordVisible ? <FaEyeSlash /> : <FaEye/>}
          </span>
        </div>

        <div className="flex__between">
          <div className="center__flex gap1">
            <p>Remember me?</p>
            {!rememberMe ?
              <span className='center__flex span__hover' onClick={()=>{setRememberMe(true)}}> <FaRegSquare/> </span>
            :
              <span className='center__flex span__hover' onClick={()=>{setRememberMe(false)}}><FaCheckSquare/></span>
            }
          </div>
          <button style={{padding: '0.5rem 1rem', fontWeight: 900}} disabled={!isFilled} className={`${!isFilled ? 'disabled' : 'span__hover'} center__flex `}  onClick={signIn}>SIGN IN</button>
        </div>
      </>
    )
  }

export default SignIn