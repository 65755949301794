import { useNavigate } from 'react-router-dom';
import { auth } from '../App/Functions/firebase-config';
import './ErrorPage.css'
const ErrorPage = () => {
  const navigate = useNavigate()
  const signOut = async () => {
    try {
      document.documentElement.style.setProperty('--color-mode-main', '#FFFFFF');
      document.documentElement.style.setProperty('--color-mode-secondary', 'whitesmoke');
      document.documentElement.style.setProperty('--text-color', 'grey');
      document.documentElement.style.setProperty('--color-text', 'grey');
      await auth.signOut();
      navigate('/')
    } catch (err) {
      alert('Error signing out')
    }
  };
  return (
    <div className="error-page">
      <div className="error-logo">
        <h1>siteHUB</h1>
      </div>
      <h1>Something went wrong!</h1>
      <p className="error-msg">Please try again later or contact support for assistance.</p>
      <span onClick={signOut} className="home-link">Take me to home</span>
    </div>
  );
}

export default ErrorPage;