import { useEffect } from 'react';
import ModalBg from '../ModalBg/ModalBg';
import './ReUse.css';

const Success = ({ success, setSuccess, }) => {



  useEffect(()=>{
    setTimeout(() => {
      setSuccess(false);
    }, 1500);
  },[success])


  return (
    <ModalBg open={success} closePopUp={()=>{setSuccess(false)}}>
      <div className="pop-up copied">
        <p>{success}</p>
      </div>
    </ModalBg>
  );
};

export default Success;