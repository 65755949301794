import './Modalbg.css'
import { useEffect, useRef } from 'react';

const ModalBg = ({ open, closePopUp, children }) => {
    const clickRef = useRef();
    useEffect(() => {
        let handler = (e)=>{
          if(!clickRef.current.contains(e.target)){
                closePopUp()
          }      
        };
        document.addEventListener("mousedown", handler);
        return() =>{
          document.removeEventListener("mousedown", handler);
        }
      });
    return (
      <div className={`modalBg ${open? 'active' : 'inactive'}`}>
        <div ref={clickRef}>
          {children}
        </div>
      </div>
    );
  };

export default ModalBg;